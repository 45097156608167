'use strict';

import Tabs from '../../_partials/common-functions/tabs';

export default class PersonCard {
  constructor() {
    this.sectionClassName = '.person-card';
    this.sectionNodeList = document.querySelectorAll(this.sectionClassName);
  }

  _initTabs() {
    const tabParams = {
      tabsNavInnerClassName: '.person-card',
      tabNavItemClassName: '.person-card__tab-item',
      tabNavItemActiveClassName: '.person-card__tab-item--active',
      tabNavItemActiveClassListName: 'person-card__tab-item--active',
      tabNavLinkClassName: '.person-card__tab-link',
      tabClassName: '.person-card__tab',
      tabActiveClassListName: 'person-card__tab--active'
    };
    const tabsPersonCard = new Tabs(tabParams);
    tabsPersonCard.init();
  }

  init() {
    if(this.sectionNodeList) {
      this._initTabs();
    }
  }

}
