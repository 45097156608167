'use strict';

export default class MainApp {

  constructor() {
  }

  init() {
    $(() => {
      $('[data-tooltipster="inline"]').tooltipster({
        arrow: false,
        minWidth: 100,
        maxWidth: 380,
        side: ['bottom', 'right', 'left'],
        contentAsHTML: true
      });

      $('[data-tooltipster="click"]').tooltipster({
        arrow: false,
        minWidth: 100,
        maxWidth: 380,
        side: ['bottom', 'right', 'left'],
        trigger: 'click',
        contentAsHTML: true
      });

      $('body')
        .on('click', '[data-tooltipster="template_html"]', e => {
          e.preventDefault();
        })
        .on('mouseenter', '[data-tooltipster="template_html"]', e => {
          const templateId = $(e.target).attr('data-tooltipster'),
                contentUrl = $(e.target).attr('href'),
                jqxhr = $.get(contentUrl);
          jqxhr
            .done(data => {
              $('#' + templateId).html(data);
              setTimeout(() => {
                $(e.target).tooltipster({
                  arrow: false,
                  minWidth: 100,
                  maxWidth: 380,
                  side: ['bottom', 'right', 'left'],
                  content: $(e.target).attr('id'),
                  contentCloning: true,
                  interactive: true,
                  contentAsHTML: true
                }).tooltipster('show');
              }, 4);
            })
            .fail(() => {
              $('#' + templateId).html('При загрузке произошла ошибка…');
            });
        });
    });
  }

}
