import Player from '../../_partials/audio-player/audio-player';
import Pagination from '../../_partials/pagination/pagination';

export default class InterviewNotLogged {

  constructor() {
    this.tableBody = document.querySelector('.table__tbody');
    this.audioCollection = document.querySelectorAll('.audio-player');
    this.paginationContainer = document.querySelector('.page__content-pagination-audio');
  }

  init() {
    if (this.tableBody) {
      this.tableBody.addEventListener('click', (e) => {
        const currentTd = e.target;
        currentTd.classList.contains('table__td--location') && (window.location = currentTd.parentNode.getAttribute('data-location'));
      });
    }

    if (this.audioCollection) {
      [...this.audioCollection].map(audio => {
        this.player = new Player(audio);
        this.player.init(true);
      });
    }

    if (this.paginationContainer) {
      this.pagination = new Pagination();
      this.pagination.init({
        pageLimit: 10
      });
    }
  }

}
