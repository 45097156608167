'use strict';

export default class Popup {
  constructor(params) {
    this.params = params;
    this.classNameSelector = params.classNameSelector;
  }

  _initPopup() {
    let $popup = $(this.classNameSelector);
    $popup.magnificPopup(this.params);
  }

  init() {
    if ($(this.classNameSelector).length) {
      this._initPopup();
    }
  }
}
