'use strict';

export default class Tabs {
  constructor(params) {
    this.name = 'tab-' + params.name;
    this.tabsNavInnerClassName = params.tabsNavInnerClassName;
    this.tabNavItemClassName = params.tabNavItemClassName;
    this.tabNavItemActiveClassName = params.tabNavItemActiveClassName;
    this.tabNavItemActiveClassListName = params.tabNavItemActiveClassListName;
    this.tabNavLinkClassName = params.tabNavLinkClassName;
    this.tabClassName = params.tabClassName;
    this.tabActiveClassListName = params.tabActiveClassListName;
    this.tabItemsNodeList = document.querySelectorAll(this.tabNavItemClassName);
    this.tabLinksNodeList = document.querySelectorAll(this.tabNavLinkClassName);
    this.tabsNodeList = document.querySelectorAll(this.tabClassName);
    this.tabNavLinksArr = [...this.tabLinksNodeList];
    this.tabsArr = [...this.tabsNodeList];
  }


  _toggleTabs(e) {
    e.preventDefault();
    if (this.tabLinksNodeList && !e.target.closest(this.tabNavItemClassName).classList.contains(this.tabNavItemActiveClassListName)) {

      let innerTabs = e.target.closest(this.tabsNavInnerClassName);
      let thisTabNavItemsArr = [ ...innerTabs.querySelectorAll(this.tabNavItemClassName)];
      let thisTabArr = [...innerTabs.querySelectorAll(this.tabClassName)];
      let linkTab = e.currentTarget.getAttribute('href');

      thisTabNavItemsArr.map(element => {
        element.classList.remove(this.tabNavItemActiveClassListName);
      });
      e.target.closest(this.tabNavItemClassName).classList.add(this.tabNavItemActiveClassListName);

      thisTabArr.map(element => {
        element.classList.remove(this.tabActiveClassListName);
      });

      innerTabs.querySelector('[data-id=' + linkTab + ']').classList.add(this.tabActiveClassListName);

      window.dispatchEvent(new Event('resize'));
    }
  }

  init() {
    if (this.tabItemsNodeList) {
      this.tabNavLinksArr.map(element => {
        element.addEventListener( 'click', e => this._toggleTabs(e) );
      });
    }
  }
}
