import KeywordsUtill from '../../_partials/keywordsUtill/keyword_utill';

export default class Keywords {
  constructor() {
    this.keyWordsSection = document.querySelector('.keywords');
    this.interviewSection = document.querySelector('.page__inner-content--interview');
  }

  // обработка клика по ключевому слову(удаление)
  handleKeywordClick(e) {
    const arr = KeywordsUtill.instance();
    const button = e.target.closest('.btn--del-keywords');
    const hiddenTagsContainer = KeywordsUtill.instance().formTags;

    if (button) {
      this.removeKeyword(button.dataset.id);
      if (!button.parentElement.classList.contains('keywords__item--input') &&
        button.parentElement.getAttribute('id') !== 'keyword-date-range'
      ) {
        const checkbox = document.getElementById(button.dataset.id);
        const childrenItems = checkbox.closest('.filters__result-item').parentElement.querySelectorAll('.filters__result-item');
        const mainCategoryItem = checkbox.closest('.filters__result-item').parentElement.querySelectorAll('.checkbox__input')[0],
          headCategoryItem = mainCategoryItem.closest('.filters__result-item--has-sublist');
        let headCategoryCheckbox;
        if (headCategoryItem) {
          headCategoryCheckbox = headCategoryItem.querySelectorAll('.filters__inner-item')[0].querySelector('.checkbox__input');
        }

        const checkboxesStatus = [...childrenItems].map(item => {
          if (!item.classList.contains('filters__result-item--main-category')) {
            const listItem = item.querySelector('.filters__checkbox-result');
            const chkbx = listItem.closest('.filters__result-list').querySelectorAll('.filters__checkbox-result--category');
            [...chkbx].map(k => {
              k.classList.remove('filters__checkbox-result--active');
            });
            if (listItem.getAttribute('for') === button.dataset.id) {
              listItem.classList.remove('filters__checkbox-result--active');
            }
          }
          if (!item.classList.contains('filters__result-item--main-category')) {
            return (item.querySelector('.checkbox__input').checked);
          }
        });

        if (headCategoryItem) {
          mainCategoryItem.checked = !checkboxesStatus.includes(false);
          headCategoryCheckbox.checked = !checkboxesStatus.includes(false);
        }
      } else {
        const newTitle = button.dataset.id;
        if (arr.keywordsArr.length > 0) {
          arr.keywordsArr.splice(arr.keywordsArr.indexOf(newTitle), 1);
          if (hiddenTagsContainer) {
            const inputs = hiddenTagsContainer.getElementsByTagName('input');
            [...inputs].map(input => {
              if (input.id === newTitle) {
                input.remove();
              }
            });
          }
        }
      }
    }
  };

  // отобразить ключевое слово
  addKeyword(title, id, tagClass, category, time) {
    if (!document.getElementById(`keyword-${id}`)) {
      let timeArr = [], timeTpl = '';
      if (this.interviewSection && time !== null) {
        timeArr.push(time.split(','));
        timeArr.map((item) => {
          item.map(el => {
            const min = Math.floor(el / 60), sec = el % 60;
            timeTpl += `<a href="#" class="keywords__link" data-time="${el}">[${min}min${sec !== 0 ? ` ${sec}sec]` : `]`}</a>`;
          });
        });
      }
      const keyword = document.createElement('li');
      const cat = !this.interviewSection && category ? `<span class="keywords__category">${category}:&nbsp;</span>` : '';
      keyword.className = tagClass !== null && id !== 'date-range' ? 'keywords__item keywords__item--input' : 'keywords__item';
      keyword.id = `keyword-${id}`.replace(/\s+/g, '_').toLowerCase();
      keyword.innerHTML = `${cat}<span class="keywords__text">${title}</span>${timeTpl}
      <button data-id=${id} type="button" class="btn btn--transparent btn--del-keywords">Удалить
      <svg><use xlink:href="#close"></use></svg>
      </button>`;
      keyword.addEventListener('click', e => {
        this.handleKeywordClick(e);
      });
      this.keyWordsSection.appendChild(keyword);
    }
  };

  // удалить ключевое слово
  removeKeyword(id) {
    const keyword = document.getElementById(`keyword-${id}`);
    const filter = document.getElementById(id);

    keyword && keyword.remove();
    filter ? filter.checked = false : null;
  };

  init() {
  }

};
