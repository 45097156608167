'use strict';

class KeywordsUtill {

  constructor () {
    this.keywordsArr = [];
    this._instance = null;
    this.formTags = document.getElementById('form_tags');
    this.rangePagination = [];
    this.rangePaginationWithDots = [];
    this.pageLimit = 1;
  }

  instance() {
    if (this._instance) {
      return this._instance;
    }

    this._instance = new KeywordsUtill();
    return this._instance;
  }

}

export default new KeywordsUtill();
