import Player from "../../_partials/audio-player/audio-player";

export default class Interviews {

  constructor() {
    this.tableBody = document.querySelector('.table');
    this.audioCollection = document.querySelectorAll('.audio-player');
  }

  init() {
    if (this.tableBody) {
      this.tableBody.addEventListener('click', (e) => {
        const currentTd = e.target;
        currentTd.classList.contains('table__td--location') && (window.location = currentTd.parentNode.getAttribute('data-location'));
      });
    }

    if (this.audioCollection) {
      [...this.audioCollection].map(audio => {
        this.player = new Player(audio);
        this.player.init(false);
      });
    }
  }

}

