'use strict';

export default class Search {
  constructor() {
    this.name = 'search';
    this.body = document.querySelector('body');
    this.btnSearch = document.querySelector('.btn--search-navbar');
    this.pageSearch = document.querySelector('.search');
    this.overlay = document.querySelector('.overlay');

    // console.log('%s module', this.name.toLowerCase());
  }

  _openSearch(e) {
    this.body.classList.add('active-overlay', 'active-search');
    this.pageSearch.querySelector('[name="search-navbar"]').focus();
  }

  _closeSearch(e) {
    this.body.classList.remove('active-overlay', 'active-search');
  }

  init() {
    if(this.btnSearch) {
      this.btnSearch.addEventListener('click', () => this._openSearch());
    }

    if(this.overlay) {
      this.overlay.addEventListener('click', (e) => this._closeSearch(e));
    }
  }
}
