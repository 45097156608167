'use strict';

import 'owl.carousel';
import Popup from '../../_partials/common-functions/popup';

export default class Slider {
  constructor(paramsSlider) {
    this.name = 'slider';

    this.paramsSlider = paramsSlider;
    this.sectionClassName = '.slider__inner';
  }

  _initSlider() {
    let owl = $(this.sectionClassName);

    owl.owlCarousel(this.paramsSlider);

    $('.btn--prev-slider-video').click(() => {
      owl.trigger('prev.owl.carousel');
    });

    $('.btn--next-slider-video').click(() => {
      owl.trigger('next.owl.carousel');
    });
  }

  _addPopupPhoto() {
    const popupParams = {
      name: 'slider-photo',
      classNameSelector: '.slider__play-nav:not(.slider__play-nav--video)',
      type: 'image',
      tLoading: 'Загрузка изображения #%curr%...',
      mainClass: 'popup-photo',
      gallery: {
        enabled: true,
        arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%">' +
          '<svg class="icon-arrow-left">' +
          '<use xlink:href="#arrow-left"></use>' +
          '</svg>' +
          '<svg class="icon-arrow-right">' +
          '<use xlink:href="#arrow-right"></use>' +
          '</svg>' +
          '</button>',
        tCounter: ''
      },
      closeMarkup: '<button title="Закрыть модальное окно" type="button" class="mfp-close popup-close">' +
        '<svg class="icon-close-popup">\n' +
        '<use xlink:href="#close-big"></use>\n' +
        '</svg>' +
        '</button>',
      image: {
        tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
        titleSrc: item => {
          return '<span class="photo-caption">' +
            item.el.attr('title') +
            '</span>' +
            '<span class="photo-date">' +
            item.el.attr('data-date-photo') +
            '</span>';
        }
      },
      callbacks: {
        open: () => {
          const magnificPopup = $.magnificPopup.instance,
            $arrows = $('.mfp-arrow');
          $('.mfp-arrow').remove();
          $arrows.insertAfter('.mfp-figure');
          $(document)
            .on('click', '.mfp-arrow-right', () => {
              magnificPopup.next();
            })
            .on('click', '.mfp-arrow-left', () => {
              magnificPopup.prev();
            });
          $('.popup-close').on('click', e => {
            $.magnificPopup.close();
          });
        },
        buildControls: () => {
          this.arrowLeft && this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
        }
      }
    };

    const popup = new Popup(popupParams);
    popup.init();
  }

  _addPopupVideo() {
    const popupParams = {
      name: 'slider-video',
      classNameSelector: '.slider__play-nav--video',
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
      showCloseBtn: true,
      closeBtnInside: true,
      closeMarkup: '<button title="Закрыть модальное окно" type="button" class="mfp-close popup-close">' +
        '<svg class="icon-close-popup">\n' +
        '<use xlink:href="#close-big"></use>\n' +
        '</svg>' +
        '</button>',
      callbacks: {
        open: () => {
          $('.popup-close').on('click', () => {
            $.magnificPopup.close();
          });
        },
      }
    };

    const popup = new Popup(popupParams);
    popup.init();
  }

  init() {
    if ($(this.sectionClassName).length) {
      this._initSlider();
      this._addPopupPhoto();
      this._addPopupVideo();
    }
  };
}
