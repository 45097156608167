// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

// import $ from 'jquery';
// import Link from '../_modules/link/link';

import MainMenu from '../_modules/main-menu/main-menu';
import Search from '../_modules/search/search';
import FilterSearch from '../_modules/filter-search/filter-search';
import PersonCard from '../_modules/person-card/person-card';
import Slider from '../_modules/slider/slider';
import Cemetery from '../_modules/cemetery/cemetery';
import ContentTabs from '../_partials/content-tabs/content-tabs';
import Sticky from '../_modules/sticky/sticky';
import InterviewView from '../_modules/interview/interview';
import Interviews from '../_modules/interviews/interviews';
import Filters from '../_partials/filters/filters';
import MainApp from '../plugins/app';
import Epigraphic from '../_modules/epigraphic/epigraphic';
import InterviewNotLogged from '../_modules/interview-not-logged/interview-not-logged';
import GalleryThumbnails from '../_partials/gallery2/gallery2';
import Keywords from '../_partials/keywords/keywords';
import Pagination from '../_partials/pagination/pagination';

class Main {

  constructor() {
    this.mainMenu = document.querySelector('.main-menu');
    this.btnSearch = document.querySelector('.btn--search-navbar');
    this.filterSearch = document.querySelector('.filter-search');
    this.personCard = document.querySelector('.person-card');
    this.slider = document.querySelector('.slider');
    this.sliderCemetery = document.querySelector('.slider--cemetery');
    this.sidebarFilters = document.querySelector('.filters--sidebar');
    this.contentTabs = document.querySelector('.content-tabs');
    this.cemetery = document.querySelector('.cemetery');
    this.epigraphic = document.querySelector('.epigraphic');
    this.interviewView = document.querySelector('.interview');
    this.interviewNotLogged = document.querySelector('.interview-not-logged');
    this.interviews = document.querySelector('.interviews');
    this.pagination = document.querySelector('.pagination--view-interview');
    this.stickySidebar = document.querySelector('.sidebar');
    this.keywords = document.querySelector('.keywords');
    this.galleryThumbnails = document.querySelector('.gallery2');
  }

  init() {
    if (this.mainMenu) {
      const mainMenu = new MainMenu();

      mainMenu.init();
    }

    if (this.btnSearch) {
      const search = new Search();
      search.init();
    }

    if (this.filterSearch) {
      const filterSearch = new FilterSearch();

      filterSearch.init();
    }

    if (this.contentTabs) {
      const contentTabs = new ContentTabs();

      contentTabs.init();
    }

    if (this.cemetery) {
      const cemetery = new Cemetery();

      cemetery.init();
    }

    if (this.personCard) {
      const personCard = new PersonCard();
      personCard.init();
    }

    const toggleNavButtons = event => {
      let imagesLength = event.target.querySelectorAll('.owl-item').length;
      let imagesLengthParams = event.page.size;
      if (imagesLength <= imagesLengthParams) {
        document.querySelector('.btn--prev-slider-video').classList.add('btn--none');
        document.querySelector('.btn--next-slider-video').classList.add('btn--none');
      } else {
        document.querySelector('.btn--prev-slider-video').classList.remove('btn--none');
        document.querySelector('.btn--next-slider-video').classList.remove('btn--none');
      }
    };

    if (this.slider) {
      let paramsSlider = {
        loop: false,
        margin: 20,
        dots: false,
        responsive: {
          480: {
            items: 2
          },
          600: {
            items: 4
          }
        },
        onInitialized: toggleNavButtons
      };
      const slider = new Slider(paramsSlider);
      slider.init();
    }

    if (this.sliderCemetery) {
      let paramsSlider = {
        loop: true,
        margin: 20,
        dots: false,
        responsive: {
          480: {
            items: 2
          },
          600: {
            items: 5
          }
        },
        onInitialized: toggleNavButtons
      };
      const sliderCemetery = new Slider(paramsSlider);
      sliderCemetery.init();
    }

    if (this.sidebarFilters) {
      const filterKeywordsViewInterview = new Filters('--sidebar');
      filterKeywordsViewInterview.init();
    }

    if (this.stickySidebar) {
      const stickySidebar = new Sticky();
      stickySidebar.init(this.stickySidebar);
    }

    if (this.epigraphic) {
      const epigraphic = new Epigraphic();
      epigraphic.init();
    }

    if (this.interviewView) {
      const interviewView = new InterviewView();
      interviewView.init();
    }

    if (this.interviewNotLogged) {
      const interviewNotLogged = new InterviewNotLogged();
      interviewNotLogged.init();
    }

    if (this.pagination) {
      const pagination = new Pagination();
      pagination.init();
    }
    if (this.keywords) {
      const keywords = new Keywords();
      keywords.init();
    }

    if (this.interviews) {
      const interviews = new Interviews();
      interviews.init();
    }

    if (this.galleryThumbnails) {
      const galleryThumbnails = new GalleryThumbnails();
      galleryThumbnails.init();
    }

  }
}

document.addEventListener('DOMContentLoaded', () => {
  const main = new Main();
  const app = new MainApp();
  main.init();
  app.init();
});
