import '../../plugins/howler.min';

export default class Player {
  constructor(audio) {
    this.player = audio;
    this.playBtn = audio.querySelector('.btn--play-audio');
    this.pauseBtn = audio.querySelector('.btn--pause-active');
    this.soundFile = audio.dataset.src;
    this.progressBar = audio.querySelector('.progress-bar');
    this.seekBar = audio.querySelector('.progress');
    this.timer = audio.querySelector('.audio-player__timer');
    this.jumpBlock = document.querySelector('.b-int__block');
    this.volumeBar = audio.querySelector('.progress-bar--volume');
    this.volumeControlBar = audio.querySelector('.progress--volume-control');
    this.muteBtn = audio.querySelector('.audio-player__toggle-volume');
    this.mutedVolume = 0;
    this.interview = audio.closest('.table__tr');
    this.playerCell = audio.closest('.table__td');
    this.cellText = this.playerCell && this.playerCell.querySelector('.table__td-text');
    this.limitation = false;
  }

  play() {

    if (this.interview) {
      [...window.Howler._howls].map(player => {

        const playBtnCollection = document.querySelectorAll('.btn--play-interview');
        const pauseBtnCollection = document.querySelectorAll('.btn--pause-interview');
        const playerCollection = document.querySelectorAll('.audio-player');

        [...playBtnCollection].map(playBtn => {
          playBtn.style.display = 'flex';
        });

        [...pauseBtnCollection].map(pauseBtn => {
          pauseBtn.style.display = 'none';
        });

        [...playerCollection].map(player => {
          player.style.display = 'none';
          player.closest('.table__td').querySelector('.table__td-text').style.display = 'flex';
        });

        player.pause();

      });

      if (this.player.style.display !== 'block') {
        this.cellText.style.display = 'none';
        this.player.style.display = 'block';
      }
    }

    this.howlPlayer.play();
    this.playBtn.style.display = 'none';
    this.pauseBtn.style.display = 'flex';
  };

  pause() {
    if (this.interview) {
      if (this.player.style.display === 'block') {
        this.cellText.style.display = 'block';
        this.player.style.display = 'none';
      }
    }

    this.howlPlayer.pause();
    this.playBtn.style.display = 'flex';
    this.pauseBtn.style.display = 'none';
  };

  stop() {
    this.howlPlayer.stop();
    this.handlePlayEnd();
  }

  setProgress() {
    const _duration = this.limitation ? 600 : Math.round(this.howlPlayer.duration());
    const _seek = this.howlPlayer.seek() || 0;

    this.progressBar.style.width = `${_seek / _duration * 100 || 0}%`;
    this.timer.innerHTML = Player.formatTime(parseFloat(Math.round(_seek).toFixed(2)));

    if (this.howlPlayer.playing()) {
      requestAnimationFrame(this.setProgress.bind(this));
    } else {
      cancelAnimationFrame(requestAnimationFrame(this.setProgress.bind(this)));
    }

    if (_seek > _duration) {
      this.stop();
    }
  };

  setVolume(e) {
    let volume = 100;
    if (e.target.closest('.btn--toggle-volume')) {
      if (this.mutedVolume > 0) {
        volume = this.mutedVolume;
        this.mutedVolume = 0;
      } else {
        this.mutedVolume = this.howlPlayer.volume();
        volume = 0;
      }
    } else {
      volume = e.offsetX / e.target.closest('.progress').offsetWidth;
      this.mutedVolume = 0;
    }
    this.volumeBar.style.width = `${volume * 100}%`;
    this.howlPlayer.volume(volume);
  };

  static formatTime(secs) {
    const minutes = Math.floor(secs / 60) || 0;
    const seconds = (secs - minutes * 60) || 0;

    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  };

  seek(e) {
    const _duration = this.limitation ? 600 : Math.round(this.howlPlayer.duration());
    const _position = e.offsetX / e.target.closest('.progress').offsetWidth * _duration;
    this.howlPlayer.seek(_position);
    this.setProgress();
  };

  jump(time) {
    if (Math.round(this.howlPlayer.duration()) > time) {
      this.howlPlayer.seek(time).pause();
    }
  };

  handlePlayEnd() {
    this.playBtn.style.display = 'flex';
    this.pauseBtn.style.display = 'none';

    if (this.interview) {
      if (this.player.style.display === 'block') {
        this.cellText.style.display = 'block';
        this.player.style.display = 'none';
      }
    }
  };

  preload() {
    this.play();
    this.stop();
  }

  init(limitation) {
    this.limitation = limitation;

    if (this.interview) {
      this.playBtn = this.interview.querySelector('.btn--play-interview');
      this.pauseBtn = this.interview.querySelector('.btn--pause-interview');
    }

    this.howlPlayer = new Howl({
      src: [this.soundFile],
      html5: true,
      preload: false,
      onplay: () => this.setProgress(),
      onseek: () => this.setProgress(),
      onend: () => this.handlePlayEnd()
    });

    this.preload();

    if (this.playBtn) {
      this.playBtn.addEventListener('click', () => this.play());
    }

    if (this.pauseBtn) {
      this.pauseBtn.addEventListener('click', () => this.pause());
    }

    if (this.seekBar) {
      this.seekBar.addEventListener('click', e => this.seek(e));
    }

    if (this.jumpBlock) {
      this.jumpBlock.addEventListener('click', e => {
        if (e.target.closest('.jump')) {
          this.jump(e.target.closest('.jump').dataset.time);
        }
      });
    }

    if (this.volumeControlBar && this.muteBtn) {
      this.volumeControlBar.addEventListener('click', e => this.setVolume(e));
      this.muteBtn.addEventListener('click', e => this.setVolume(e));
    }
  }
}
