'use strict';

import Filters from '../../_partials/filters/filters';


export default class FilterSearch {
  constructor() {
    this.name = 'filter-search';
    this.tabInners = document.querySelectorAll('.nav-tabss__item');
    this.tabLinks = document.querySelectorAll('.nav-tabss__link');
    this.filters = document.querySelectorAll('.filters__select');
    this.tabLinksArr = [...this.tabLinks];
    this.tabs = document.querySelectorAll('.filter-search__tab');
    this.tabsArr = [...this.tabs];
  }

  _toggleTabs(e) {
    e.preventDefault();

    if(this.tabLinks && !e.target.closest('.nav-tabss__item').classList.contains('nav-tabs__item--active')) {

      this.tabLinksArr.map( element => {
        element.closest('.nav-tabss__item').classList.remove('nav-tabs__item--active');
      });
      e.target.closest('.nav-tabss__item').classList.add('nav-tabs__item--active');

      let hrefLink = e.currentTarget.getAttribute('href');

      this.tabsArr.map( element => {
        element.classList.remove('filter-search__tab--active');
      });

      document.querySelector(hrefLink).classList.add('filter-search__tab--active');
      if (document.querySelector(hrefLink).querySelectorAll('.filter-search__input')[0]) {
        document.querySelector(hrefLink).querySelectorAll('.filter-search__input')[0].children[0].focus();
      }
    }
  }

  init() {
    if(this.tabInners) {
      this.tabLinksArr.map( element => {
        element.addEventListener('click', e => this._toggleTabs(e));
      });
    }

    if(this.filters) {
      const filterKeywords = new Filters();

      filterKeywords.init();
    }
  }
}
