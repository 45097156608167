import Keywords from '../keywords/keywords';
import KeywordsUtill from "../keywordsUtill/keyword_utill";

export default class Filters {
  constructor() {
    this.filtersSection = document.querySelectorAll('.filters');
    this.filtersSelect = document.querySelectorAll('.filters__select');
    this.filtersSearchField = document.querySelectorAll('.filters__search-input');
    this.filtersParents = document.querySelectorAll('.filters__result-item--has-sublist');
    this.filtersSubs = document.querySelectorAll('.filters__result-item--main-category');
    this.filtersCheckboxes = document.querySelectorAll('.filters__checkbox-result');
    this.clearSearchButton = document.querySelectorAll('.btn--close-input-search-keywords');
    this.filtersLetters = document.querySelectorAll('.filters__alphabet-letter');
    this.filtersTabs = document.querySelectorAll('.filters__tab');
    this.filtersCalendar = document.querySelector('.filters__result--calendar');
    this.filtersCalendarFrom = document.getElementById('filters-date-range-from');
    this.filtersCalendarTo = document.getElementById('filters-date-range-to');
    this.keyWordsContainer = document.querySelector('.keywords');
    this.searchInput = document.getElementById('list-interviews');
    this.clearLink = document.querySelector('.filter-search__clear-link');
  }

  // поиск элемента по массиву
  static findArr(array, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] === value) return i;
    }
    return -1;
  }

  searchInputTags() {
    if (this.searchInput) {
      const arr = KeywordsUtill.instance();
      const hiddenTagsContainer = KeywordsUtill.instance().formTags;
      this.searchInput.addEventListener('keypress', e => {
        const key = e.which || e.keyCode,
          $input = this.searchInput;
        if (key === 13) {
          e.preventDefault();
          let keyFiltered = $input.value.replace(/\s+/g, '_').toLowerCase(),
            arrResult = Filters.findArr(arr.keywordsArr, keyFiltered);
          if ($input.value.length > 1) {
            if (arrResult === -1) {
              const hiddenInput = document.createElement('input');
              hiddenInput.setAttribute('type', 'hidden');
              hiddenInput.setAttribute('id', keyFiltered);
              hiddenInput.setAttribute('name', this.searchInput.dataset.param);
              hiddenInput.setAttribute('value', $input.value);
              if (hiddenTagsContainer) {
                hiddenTagsContainer.appendChild(hiddenInput);
              }
              this.keyWords.addKeyword($input.value, keyFiltered, 1, null, null);
              arr.keywordsArr.push(keyFiltered);
              $input.value = '';
            } else {
              alert(`Такой тег уже существует`);
            }
          } else {
            alert(`Введите минимум 2 символа`);
          }
        }
      });
    }
  }

  static clearSearchResults(filterItem) {
    if (filterItem) {
      const currentResults = filterItem.closest('.filters__dropdown').querySelectorAll('.filters__result-item--search-result');
      [...currentResults].map(result => {
        result.remove();
      });
    }
  }

  static showInSearchResults(filterItem) {
    if (filterItem) {
      const resultList = filterItem.closest('.filters__dropdown').querySelector('.filters__result-list');

      const li = document.createElement('li');
      li.className = 'filters__result-item filters__result-item--search-result';
      li.innerHTML = filterItem.innerHTML;

      const input = li.querySelector('.filters__checkbox-result');

      if (filterItem.querySelector('.checkbox__input').checked) {
        input.querySelector('.checkbox__input').checked = true;
      }

      input.addEventListener('click', e => {
        if (e.target) {
          e.target.checked = !e.target.checked;
        }
      });

      resultList.appendChild(li);

    }
  }

  hideAllFilters(current) {
    [...this.filtersSelect].map(select => {
      if (select.classList.contains('filters__select--active')) {
        current !== select && select.classList.remove('filters__select--active');
      }

      [...select.parentElement.querySelectorAll('.filters__result-item')].map(li => {
        const activeTab = li.closest('.filters__dropdown') && li.closest('.filters__dropdown').querySelector('.filters__tab--active'),
          activeTabId = activeTab && activeTab.dataset.tab;
        const isActiveTab = activeTabId ? li.closest('.filters__result-item').dataset.tab === activeTabId : true;
        const searchInputDiv = li.closest('.filters__dropdown').querySelector('.filters__search');
        if (searchInputDiv) {
          searchInputDiv.querySelector('.filters__search-input').value = '';
          searchInputDiv.querySelector('.btn--close-input-search-keywords').classList.remove('btn--active');
        }

        li.classList.contains('filters__result-item--search-result') && li.remove();

        if (isActiveTab) {
          li.style.display = 'inline-block';
        }
      });
    });
  }

  handleBodyClick(e) {
    if (!e.target.closest('.filters__item')) {
      this.hideAllFilters();
    }
  }

  handleSelectClick(e) {
    const select = e.target.closest('.filters__select');
    this.hideAllFilters(select);

    if (select) {
      select.classList.toggle('filters__select--active');
      const input = select.parentElement.querySelector('.filters__search-input');
      input && setTimeout(() => {
        input.focus()
      }, 100);
    }
  }

  handleSearchChange(e) {
    let count = 0;
    const clearSearchButton = e.target.closest('.filters__dropdown').querySelector('.btn--close-input-search-keywords'),
      currentList = e.target.closest('.filters__dropdown').querySelector('.filters__result-list'),
      activeTab = e.target.closest('.filters__dropdown') && e.target.closest('.filters__dropdown').querySelector('.filters__tab--active'),
      activeTabId = activeTab && activeTab.dataset.tab;

    Filters.clearSearchResults(e.target);

    if (e.target.value.length >= 2) {
      const filterItems = e.target.closest('.filters__dropdown').querySelectorAll('.filters__checkbox-result');

      clearSearchButton.classList.add('btn--active');

      [...currentList.children].map(item => {
        item.style.display = 'none';
      });

      [...filterItems].filter(item => {
        const isActiveTab = activeTabId ? item.closest('.filters__result-item').dataset.tab === activeTabId : true;
        const tabs = item.closest('.filters__result-list--tabs');

        if (isActiveTab
          && item.textContent.toLowerCase().trim().indexOf(e.target.value.toLowerCase()) > -1
          && tabs === null
        ) {
          if (!item.classList.contains('filters__checkbox-result--category')) {
            Filters.showInSearchResults(item.closest('.filters__inner-item'));
          }
        }

        if (isActiveTab && tabs !== null) {
          const filterItem = item.closest('.filters__result-list--tabs').querySelectorAll('.filters__result-item'),
            filtered = [...filterItem].filter(item => {
              const text = item.querySelector('.filters__checkbox-result').textContent.toLowerCase().trim();
              return text.indexOf(e.target.value.toLowerCase()) > -1;
            });
          count++;
          if (count > 1) return;
          [...filtered].map(searched => {
            searched.closest('.filters__result-item--has-sublist').dataset.tab === activeTabId
            && Filters.showInSearchResults(searched);
          });
        }
      });
    } else {
      clearSearchButton.classList.remove('btn--active');

      [...currentList.children].map(item => {
        const isActiveTab = activeTabId ? item.closest('.filters__result-item').dataset.tab === activeTabId : true;

        if (isActiveTab) {
          item.style.display = 'inline-block';
        }
      });
    }
  }

  static handleClearSearchButtonClick(e) {
    e.target.closest('.btn--close-input-search-keywords').classList.remove('btn--active');
    e.target.closest('.filters__search').querySelector('.filters__search-input').value = '';
    const activeTab = e.target.closest('.filters__dropdown').querySelector('.filters__tab--active'),
      activeTabId = activeTab && activeTab.dataset.tab;

    [...e.target.closest('.filters__dropdown').querySelector('.filters__result-list').children].map(item => {
      const isActiveTab = activeTabId ? item.dataset.tab === activeTabId : true;
      if (isActiveTab) {
        item.style.display = 'inline-block';
      }
    });

    Filters.clearSearchResults(e.target);
  }

  static handleOpenSublistClick(e) {
    const activeItem = e.target.closest('.filters__result-item--has-sublist');
    const sublist = activeItem.querySelector('.filters__result-sublist');
    const activeItemParent = activeItem.closest('.filters__result-sublist');

    activeItemParent ? activeItemParent.style.overflow = 'hidden' : null;
    activeItemParent && (activeItemParent.scrollTop = 0);
    sublist.style.display = 'block';
  }

  static handleCloseSublistClick(e) {
    const subList = e.target.closest('.filters__result-sublist');
    const subListParent = subList.parentElement.closest('.filters__result-sublist');

    subListParent ? subListParent.style.overflow = 'auto' : null;
    subListParent && (subListParent.scrollTop = 0);
    subList.style.display = 'none';
  }

  static setItemsClass() {
    const items = document.querySelectorAll('.checkbox__input');

    [...items].map(item => {
      item.checked ?
        item.parentElement.classList.add('filters__checkbox-result--active') :
        item.parentElement.classList.remove('filters__checkbox-result--active');
    });
  };

  handleCheckboxChange(e) {
    const filterParent = e.target.closest('.filters__result-item');

    // если кликнули по чекбоксу элемента, у которого есть сабменю
    if (filterParent.classList.contains('filters__result-item--has-sublist')) {
      const childrenCheckboxes = filterParent.querySelectorAll('.checkbox__input');

      childrenCheckboxes && [...childrenCheckboxes].map(checkbox => {

        checkbox.checked = e.target.checked;

        if (!checkbox.parentElement.classList.contains('filters__checkbox-result--category')) {
          if (checkbox.dataset.category) {
            checkbox.id && this.setKeywords(
              checkbox.parentElement.textContent,
              checkbox.id,
              checkbox.checked,
              checkbox.dataset.category,
              checkbox.dataset.time
            );
          }
        }
      });
    }

    // если кликнули по чекбоксу заголовка сабменю
    if (filterParent.classList.contains('filters__result-item--main-category')) {
      const childrenCheckboxes = filterParent.parentElement.querySelectorAll('.checkbox__input');
      const filterParentCheckbox = filterParent.parentElement.closest('.filters__result-item').querySelector('.checkbox__input');

      filterParentCheckbox.checked = e.target.checked;

      childrenCheckboxes && [...childrenCheckboxes].map(checkbox => {

        checkbox.checked = e.target.checked;

        if (!checkbox.parentElement.classList.contains('filters__checkbox-result--category')) {
          if (checkbox.dataset.category) {
            checkbox.id && this.setKeywords(
              checkbox.parentElement.textContent,
              checkbox.id,
              checkbox.checked,
              checkbox.dataset.category,
              checkbox.dataset.time
            );
          }
        }
      });
    }

    // если заполнинли или не заполнили все саб элементы
    if (filterParent.parentElement.classList.contains('filters__result-sublist')) {

      const childrenItems = filterParent.parentElement.children,
        mainCategoryItem = childrenItems[0].querySelector('.checkbox__input');
      const headCategoryItem = mainCategoryItem.closest('.filters__result-item--has-sublist'),
        headCategoryCheckbox = headCategoryItem.querySelectorAll('.filters__inner-item')[0]
          .querySelector('.checkbox__input');

      const checkboxesStatus = [...childrenItems].map(item => {
        if (!item.classList.contains('filters__result-item--main-category')) {
          return (item.querySelector('.checkbox__input').checked);
        }
      });

      mainCategoryItem.checked = !checkboxesStatus.includes(false);
      headCategoryCheckbox.checked = !checkboxesStatus.includes(false);
    }

    // если клик был по конечному ключевому слову
    if (!e.target.parentElement.classList.contains('filters__checkbox-result--category')) {
      if (e.target.dataset.category) {

        e.target.id && this.setKeywords(
          e.target.parentElement.textContent,
          e.target.id,
          e.target.checked,
          e.target.dataset.category,
          e.target.dataset.time
        );

        // клик по клонированному итему
        const name = e.target.getAttribute('name');
        [...document.querySelectorAll('.checkbox__input')].map(item => {
          if (item.getAttribute('name') === name
            && item.closest('.filters__result-item').classList.contains('filters__result-item--search-result')
          ) {
            item.checked = !item.checked;
          }
        });
      }
    }

    Filters.setItemsClass();
  };

  // метод отображения активных элементов по буквам алфавитного фильтра
  setActiveLetters(letter) {
    const activeLetter = letter.closest('.filters__result-inner').querySelector('.filters__alphabet-letter--active');
    const filterItems = letter.closest('.filters__result-inner').querySelectorAll('.filters__result-item');

    [...filterItems].map(item => {
      if (activeLetter) {
        item.style.display = 'none';
        if (activeLetter.innerText.toLowerCase() === item.querySelector('.filters__checkbox-result').innerText[0].toLowerCase()) {
          item.style.display = 'inline-block';
        }
      } else {
        item.style.display = 'inline-block';
      }
    });
  };

  // обработка клика по буквам алфавита - утсановка активной буквы и вызов метода setActiveLetters
  handleLetterClick(e) {
    [...this.filtersLetters].map(letter => {
      letter !== e.target && letter.classList.remove('filters__alphabet-letter--active');
    });

    e.target.classList.contains('filters__alphabet-letter--active') ?
      e.target.classList.remove('filters__alphabet-letter--active') :
      e.target.classList.add('filters__alphabet-letter--active');

    this.setActiveLetters(e.target);
  }

  handleTabClick(e) {
    e.preventDefault();
    const filterItems = e.target.closest('.filters__dropdown').querySelectorAll('.filters__result-item');
    const filterTabs = e.target.closest('.filters__dropdown').querySelectorAll('.filters__tab');

    Filters.clearSearchResults(e.target);
    e.target.closest('.filters__dropdown').querySelector('.filters__search-input').value = '';
    e.target.closest('.filters__dropdown').querySelector('.btn--close-input-search-keywords')
      .classList.remove('btn--active');

    [...filterTabs].map(tab => {
      tab.classList.remove('filters__tab--active');
    });

    e.target.classList.add('filters__tab--active');

    [...filterItems].map(item => {
      if (e.target.dataset.tab === item.dataset.tab) {
        if (item.closest('.filters__result-item').classList.contains('filters__result-item--has-sublist')) {
          item.style.display = 'inline-block';
        }
      } else {
        if (item.closest('.filters__result-item').classList.contains('filters__result-item--has-sublist')) {
          item.style.display = 'none';
        }
      }
    });
  };

  setKeywords(title, id, active, category, time) {
    if (active) {
      this.keyWords.addKeyword(title, id, null, category, time);
    } else {
      this.keyWords.removeKeyword(id);
    }
  }

  clearFilters() {
    if (this.clearLink) {
      this.clearLink.addEventListener('click', e => {
        const hiddenTagsContainer = KeywordsUtill.instance().formTags;
        let keywordInstanceArr = KeywordsUtill.instance().keywordsArr;
        hiddenTagsContainer.innerHTML = '';
        document.querySelector('.keywords--tags').innerHTML = '';
        keywordInstanceArr.pop();
        [...this.filtersCheckboxes].map(item => {
          item.classList.remove('filters__checkbox-result--active');
          item.querySelector('.checkbox__input').checked = false;
          item.querySelector('.checkbox__input').removeAttribute('checked');
        });
      });
    }
  }

  init() {
    if (this.filtersSection) {

      this.clearFilters();

      this.searchInputTags();

      document.body.addEventListener('click', e => {
        this.handleBodyClick(e);
      });

      [...this.filtersSelect].map(select => {
        select.addEventListener('click', (e) => {
          this.handleSelectClick(e);
        });
      });

      [...this.filtersSearchField].map(input => {
        input.addEventListener('input', (e) => {
          this.handleSearchChange(e);
        });
      });

      if (this.filtersParents) {
        [...this.filtersParents].map(parent => {
          const openSubmenuButton = parent.querySelector('.btn--open-submenu-filters');
          openSubmenuButton && openSubmenuButton.addEventListener('click', (e) => {
            Filters.handleOpenSublistClick(e);
          });
        });
      }

      if (this.filtersSubs) {
        [...this.filtersSubs].map(sub => {
          const openSubmenuButton = sub.querySelector('.btn--open-submenu-filters');
          openSubmenuButton && openSubmenuButton.addEventListener('click', (e) => {
            Filters.handleCloseSublistClick(e);
          });
        });
      }

      if (this.filtersCheckboxes) {
        [...this.filtersCheckboxes].map(checkbox => {
          const input = checkbox.querySelector('.checkbox__input');
          input && input.addEventListener('change', (e) => {
            this.handleCheckboxChange(e);
          });
        });
      }

      if (this.clearSearchButton) {
        [...this.clearSearchButton].map(button => {
          button.addEventListener('click', (e) => {
            Filters.handleClearSearchButtonClick(e);
          });
        });
      }

      if (this.filtersLetters) {
        [...this.filtersLetters].map(letter => {
          letter.addEventListener('click', e => {
            this.handleLetterClick(e);
          });
        });
      }

      if (this.filtersTabs) {
        [...this.filtersTabs].map(tab => {
          tab.addEventListener('click', e => {
            this.handleTabClick(e);
          });
        });
      }

      if (this.filtersCalendar) {
        const rangeTag = document.getElementById('date-range');
        const calendar = $('.filters__result--calendar').PACalendar({
          from: {element: $('#filters-date-range-from')},
          to: {element: $('#filters-date-range-to')},
          mode: 'range',
          format: 'DD.MM.YYYY',
          locale: moment.locale('ru'),
        });

        if (rangeTag) {
          const dateArr = rangeTag.value.split(' - '),
            dateFrom = dateArr[0],
            dateTo = dateArr[1];
          calendar.setDate(dateFrom, dateTo);
          $('#filters-date-range-from').val(dateFrom);
          $('#filters-date-range-to').val(dateTo);
        }

        $(document).on('keypress', '#filters-date-range-from, #filters-date-range-to', e => {
          if (e.keyCode === 13) {
            e.preventDefault();
            if ($(e.target).prop('value') !== '') {
              this.hideAllFilters();
            }
          }
        });

        calendar.on('setDate', () => {
          if (this.filtersCalendarFrom.value && this.filtersCalendarTo.value) {
            const keyWordTitle = `${this.filtersCalendarFrom.value} - ${this.filtersCalendarTo.value}`;
            this.keyWords.removeKeyword('date-range');
            this.keyWords.addKeyword(keyWordTitle, 'date-range', null, null, null);
          }
        });
      }

      if (this.keyWordsContainer) {
        this.keyWords = new Keywords();
        const checkboxes = document.querySelectorAll('.checkbox__input');
        const hiddenTagsContainer = KeywordsUtill.instance().formTags;
        checkboxes && [...checkboxes].map(checkbox => {
          if (checkbox.dataset.category) {
            checkbox.id && this.setKeywords(
              checkbox.parentElement.textContent,
              checkbox.id,
              checkbox.checked,
              checkbox.dataset.category,
              checkbox.dataset.time
            );
          }
        });
        if (hiddenTagsContainer) {
          [...hiddenTagsContainer.children].map(item => {
            const tagId = item.getAttribute('id'),
              tagValue = item.getAttribute('value');
            KeywordsUtill.instance().keywordsArr.push(tagId);
            this.keyWords.addKeyword(tagValue, tagId, 1, null, null);
          });
        }

        [...document.querySelectorAll('.filters__result-item')].map(checkbox => {
          if (checkbox.parentElement.classList.contains('filters__result-sublist')) {
            const childrenItems = checkbox.parentElement.children,
              mainCategoryItem = childrenItems[0].querySelector('.checkbox__input');
            const headCategoryItem = mainCategoryItem.closest('.filters__result-item--has-sublist'),
              headCategoryCheckbox = headCategoryItem.querySelectorAll('.filters__inner-item')[0]
                .querySelector('.checkbox__input');

            const checkboxesStatus = [...childrenItems].map(item => {
              if (!item.classList.contains('filters__result-item--main-category')) {
                return (item.querySelector('.checkbox__input').checked);
              }
            });

            if (checkbox.querySelector('.checkbox__input').checked) {
              checkbox.querySelector('.checkbox__input').parentElement.classList.add('filters__checkbox-result--active');
            }

            mainCategoryItem.checked = !checkboxesStatus.includes(false);
            headCategoryCheckbox.checked = !checkboxesStatus.includes(false);

            mainCategoryItem.checked && mainCategoryItem.closest('.filters__checkbox-result').classList.add('filters__checkbox-result--active');
            headCategoryCheckbox.checked && headCategoryCheckbox.closest('.filters__checkbox-result').classList.add('filters__checkbox-result--active');
          }
        });
      }

    }
  }
}
