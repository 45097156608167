'use strict';

export default class Cemetery {
  constructor() {
    this.sectionNodeList = document.querySelectorAll('.cemetery');
    this.body = document.querySelector('body');
    this.overlay = document.querySelector('.overlay');
    this.tableInfoBtns = document.querySelectorAll('.cemetery-table__info-btn');
    this.tableInfoPopupCloseList = document.querySelectorAll('.cemetery-table__info-popup-close');
    this.tableBody = document.querySelector('.table__tbody');
    this.imagePopupLink = document.querySelectorAll('.image-ajax');
    this.imageArray = [];
  }

  _viewCemeteryPhotos() {
    const popupParams = {
      type: 'image',
      items: this.imageArray,
      tLoading: 'Загрузка изображения #%curr%...',
      mainClass: 'popup-photo',
      gallery: {
        enabled: true,
        arrowMarkup: `<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%">
          <svg class="icon-arrow-left"><use xlink:href="#arrow-left"></use></svg>
          <svg class="icon-arrow-right"><use xlink:href="#arrow-right"></use></svg>
          </button>`,
        tCounter: ``
      },
      closeMarkup: `<button title="Закрыть модальное окно" type="button" class="mfp-close popup-close">
        <svg class="icon-close-popup"><use xlink:href="#close-big"></use></svg>
        </button>`,
      image: {
        tError: `<a href="%url%">Изображение #%curr%</a> не может быть загружено.`,
        titleSrc: item => {
          return `<span class="photo-caption">${item.el.attr('title')}</span>
            <span class="photo-date">${item.el.attr('data-date-photo')}</span>`;
        }
      },
      callbacks: {
        open: () => {
          const magnificPopup = $.magnificPopup.instance,
            $arrows = $('.mfp-arrow');
          $('.mfp-arrow').remove();
          $arrows.insertAfter('.mfp-figure');
          $(document)
            .on('click', '.mfp-arrow-right', () => {
              magnificPopup.next();
            })
            .on('click', '.mfp-arrow-left', () => {
              magnificPopup.prev();
            });
          $('.popup-close').on('click', () => {
            $.magnificPopup.close();
          });
        },
        buildControls: () => {
          this.arrowLeft && this.contentContainer.append(this.arrowLeft.add(this.arrowRight))
        },
        close: () => {
          document.getElementById('gallery-output').innerHTML = '';
        }
      }
    };

    [...this.imagePopupLink].map(item => {
      item.addEventListener('click', e => {
        e.preventDefault();
        const url = item.getAttribute('href'),
          xhr = new XMLHttpRequest();
        xhr.open('GET', url, false);
        xhr.send();
        if (xhr.status !== 200) {
          console.log('error');
        } else {
          const galleryContainer = document.getElementById('gallery-output');
          galleryContainer.innerHTML = xhr.responseText;
          const images = galleryContainer.getElementsByTagName('img');

          [...images].map(img => {
            this.imageArray.push({
              src: img.getAttribute('src'),
              title: img.getAttribute('alt'),
              datePhoto: img.getAttribute('data-date-photo')
            });
          });
        }
        if (xhr.readyState === 4) {
          $.magnificPopup.open(popupParams);
        }
      });
    });
  }

  _showCemeteryInfo(item) {
    const infoPopup = item.nextElementSibling;
    this.body.classList.toggle('active-overlay');
    item.classList.toggle('cemetery-table__info-btn--active');
    infoPopup.classList.toggle('cemetery-table__info-popup--visible');
  }

  _hideCemeteryInfo() {
    const popup = document.querySelector('.cemetery-table__info-popup--visible');
    const btn = document.querySelector('.cemetery-table__info-btn--active');

    popup.classList.remove('cemetery-table__info-popup--visible');
    btn.classList.remove('cemetery-table__info-btn--active');
    this.body.classList.remove('active-overlay');
  }

  init() {

    this.sectionNodeList && this._viewCemeteryPhotos();

    if (this.tableInfoBtns) {
      [...this.tableInfoBtns].map(item => {
        item.addEventListener('click', () => this._showCemeteryInfo(item))
      })
    }

    if (this.tableInfoPopupCloseList) {
      [...this.tableInfoPopupCloseList].map(item => {
        item.addEventListener('click', () => this._hideCemeteryInfo())
      })
    }

    this.overlay && this.overlay.addEventListener('click', () => this._hideCemeteryInfo());

    if (this.tableBody) {
      this.tableBody.addEventListener('click', (e) => {
        const currentTd = e.target;
        currentTd.classList.contains('table__td--location') && (window.location = currentTd.parentNode.getAttribute('data-location'));
      });
    }

  }
}



