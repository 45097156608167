import KeywordsUtill from '../../_partials/keywordsUtill/keyword_utill';
import Keywords from "../keywords/keywords";

export default class Pagination {
  constructor() {
    this.AudioPaginationSection = document.querySelectorAll('.page__content-pagination-audio');
    this.paragraphCollection = document.querySelectorAll('.page__p');
    this.audioKeywordSection = document.querySelector('.page__content-timeline-keywords');
    this.nextButton = document.querySelectorAll('.pagination__next');
    this.prevButton = document.querySelectorAll('.pagination__prev');
    this.paginationListAll = document.querySelectorAll('.pagination__list');
    this.activePage = 1;
    this.pagesCount = 1;
    this.delta = 1;
    this.arr = KeywordsUtill.instance();
  };

  handlePaginationClick(item, limit) {
    if (!item) return;

    this.activePage = +item.dataset.page;

    [...document.querySelectorAll('.pagination__prev')].map(btn => {
      this.activePage !== 1 ? btn.classList.add('pagination__prev--active') : btn.classList.remove('pagination__prev--active');
    });

    [...document.querySelectorAll('.pagination__next')].map(btn => {
      btn.style.display = this.activePage === this.pagesCount ? 'none' : 'block';
    });

    [...document.querySelectorAll('.pagination__item')].map(pagination => {
      pagination.classList.remove('active');
    });

    [...document.querySelectorAll(`[data-page="${item.dataset.page}"]`)].map(pagination => {
      pagination.parentElement.classList.add('active');
    });

    [...this.paragraphCollection].map((paragraph, index) => {
      let limitPageLeft = item.dataset.page * limit,
        limitPageRight = (item.dataset.page - 1) * limit;
      if (item.dataset.page === undefined || item.dataset.page === '0') {
        limitPageLeft = limit;
        limitPageRight = 0;
      }
      if (index < limitPageLeft && index >= limitPageRight) {
        paragraph.style.display = 'block';
      } else {
        paragraph.style.display = 'none';
      }
    });
  };

  getPaginationItems(target) {
    let currentTarget, itemsMarkup = '';

    this.arr.rangePagination = [];
    this.arr.rangePaginationWithDots = [];

    target === undefined ? currentTarget = 1 : currentTarget = parseInt(target);

    const left = currentTarget - this.delta,
      right = currentTarget + this.delta + 1;

    for (let i = 1; i <= this.pagesCount; i++) {
      if (i === 1 || i === this.pagesCount || i >= left && i < right) {
        this.arr.rangePagination.push(i);
      }
    }

    for (let i of this.arr.rangePagination) {
      if (this.pagesCount) {
        if (i - this.pagesCount === 2) {
          this.arr.rangePaginationWithDots.push(this.pagesCount + 1);
        } else if (i - this.pagesCount !== 1) {
          this.arr.rangePaginationWithDots.push('...');
        }
      }
      this.arr.rangePaginationWithDots.push(i);
      this.pagesCount = i;
    }

    for (let i = 1; i < this.arr.rangePaginationWithDots.length; i++) {
      let itemTpl;
      if (this.arr.rangePaginationWithDots[i] === '...') {
        itemTpl = `<li class="pagination__item">${this.arr.rangePaginationWithDots[i]}</li>`;
      } else {
        itemTpl = `<li class="pagination__item${i === 1 ? ' active' : ''}">
          <a href="#" data-page="${this.arr.rangePaginationWithDots[i]}" class="clickPageNumber">${this.arr.rangePaginationWithDots[i]}</a>
        </li>`;
      }
      itemsMarkup += itemTpl;
    }

    return itemsMarkup;
  };


  init(options) {
    if (options === undefined) return;

    this.arr.pageLimit = options.pageLimit;

    if (this.paragraphCollection) {
      [...this.paragraphCollection].map((paragraph, index) => {
        index >= options.pageLimit && (paragraph.style.display = 'none');
      });
    }

    if (this.AudioPaginationSection) {
      [...this.AudioPaginationSection].map(pagination => {
        this.pagesCount = Math.ceil(this.paragraphCollection.length / options.pageLimit);
        const paginationList = pagination.querySelector('.pagination__list');
        const pageContent = document.querySelector('.page__content');

        pageContent.addEventListener('click', e => {
          e.preventDefault();
          if (e.target.dataset.page) {
            paginationList.innerHTML = this.getPaginationItems(e.target.dataset.page);
            this.handlePaginationClick(e.target, options.pageLimit);
          }
        });

        paginationList.innerHTML = this.getPaginationItems();

      });

      if (this.prevButton) {
        [...this.prevButton].map(element => {
          element.addEventListener('click', e => {
            e.preventDefault();
            [...this.paginationListAll].map(item => {
              item.innerHTML = this.getPaginationItems(this.activePage - 1);
            });
            this.handlePaginationClick(
              document.querySelector(`[data-page="${+this.activePage - 1}"]`),
              options.pageLimit
            );
          });
        });
      }

      if (this.nextButton) {
        [...this.nextButton].map(element => {
          if (this.pagesCount > 1) {
            element.style.display = 'block';
          }
          element.addEventListener('click', e => {
            e.preventDefault();
            [...this.paginationListAll].map(item => {
              item.innerHTML = this.getPaginationItems(this.activePage + 1);
            });
            this.handlePaginationClick(
              document.querySelector(`[data-page="${+this.activePage + 1}"]`),
              options.pageLimit
            );
          });
        });
      }
    }

    if (this.audioKeywordSection) {
      this.audioKeywordSection.addEventListener('click', e => {
        e.preventDefault();
        const num = document.querySelector(`span[data-time="${e.target.dataset.time}"]`).dataset.page;
        [...this.AudioPaginationSection].map(pagination => {
          const paginationList = pagination.querySelector('.pagination__list');
          if (num === '0') {
            this.handlePaginationClick(
              document.querySelector(`[data-page="1"]`),
              KeywordsUtill.instance().pageLimit
            );
            paginationList.innerHTML = this.getPaginationItems(1);
          } else {
            this.handlePaginationClick(
              document.querySelector(`[data-page="${num}"]`),
              KeywordsUtill.instance().pageLimit
            );
            paginationList.innerHTML = this.getPaginationItems(num);
          }
        });
      });
    }

  }
}
