'use strict';

export default class MainMenu {
  constructor() {
    this.name = 'main-menu';
    this.body = document.querySelector('body');
    this.mainMenuInner = document.querySelector('.navbar__menu');
    this.mainMenu = document.querySelector('.main-menu');
    this.closeMenuButton = document.querySelector('.btn--close-main-menu');
    this.openMenuButton = document.querySelector('.btn--open-main-menu');
    this.overlay = document.querySelector('.overlay');
    this.timer = null;

    // console.log('%s module', this.name.toLowerCase());
  }

  _openMainMenu(e) {
    if(this.mainMenuInner) {
      this.body.classList.add('active-overlay');
      this.mainMenuInner.classList.add('_active');
    }
  }

  _closeMainMenu(e) {
    if(this.mainMenuInner) {
      this.mainMenuInner.classList.remove('_active');
      this.body.classList.remove('active-overlay');
    }
  }

  _closeMainMenuClickBody(e) {
    if( (this.mainMenu) && !(e.target.classList.contains('main-menu'))) {
      this.mainMenuInner.classList.remove('_active');
      this.body.classList.remove('active-overlay');
    }
  }

  init() {
    if(this.openMenuButton) {
      this.openMenuButton.addEventListener('click', () => this._openMainMenu());
    }

    if(this.closeMenuButton) {
      this.closeMenuButton.addEventListener('click', () => this._closeMainMenu());
    }

    this.overlay.addEventListener('click', (e) => this._closeMainMenuClickBody(e));
  }

}
