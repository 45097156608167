'use strict';

export default class Sticky {
  constructor() {
    this.name = 'sticky';
    this.element = null;
    this.position = 0;
    this.activeClass = 'is-fixed';
  }

  addEvents() {
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  init(element) {
    this.element = element;
    this.addEvents();
    this.position = this.element.offsetTop ;
    this.onScroll();
  }

  aboveScroll() {
    return this.position < window.scrollY;
  }

  onScroll(event) {
    if (this.aboveScroll()) {
      this.setFixed();
    } else {
      this.setStatic();
    }
  }

  setFixed() {
    this.element.classList.add(this.activeClass);
    this.element.style.position = 'fixed';
    this.element.style.top = 0;
  }

  setStatic() {
    this.element.classList.remove(this.activeClass);
    this.element.style.position = 'relative';
    this.element.style.top = 'auto';
  }
}
