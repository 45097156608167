'use strict';

import Popup from '../../_partials/common-functions/popup';
import QuickView from "../quick-view/quick-view";

export default class Epigraphic extends QuickView {
  constructor() {
    super();
    this.sectionEpigraphic = document.querySelectorAll('.epigraphic');
    this.tableBody = document.querySelector('.table__tbody');
    this.filterSearch = document.querySelector('.filter-search__tab');
    this.imagePopupLink = document.querySelectorAll('.image-ajax');
    this.imageArray = [];
  }

  _viewEpigraphicPhotos() {
    const popupParams = {
      type: 'image',
      items: this.imageArray,
      tLoading: 'Загрузка изображения #%curr%...',
      mainClass: 'popup-photo',
      gallery: {
        enabled: true,
        arrowMarkup: `<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%">
          <svg class="icon-arrow-left"><use xlink:href="#arrow-left"></use></svg>
          <svg class="icon-arrow-right"><use xlink:href="#arrow-right"></use></svg>
          </button>`,
        tCounter: ``
      },
      closeMarkup: `<button title="Закрыть модальное окно" type="button" class="mfp-close popup-close">
        <svg class="icon-close-popup"><use xlink:href="#close-big"></use></svg>
        </button>`,
      image: {
        tError: `<a href="%url%">Изображение #%curr%</a> не может быть загружено.`,
        titleSrc: item => {
          return `<span class="photo-caption">${item.el.attr('title')}</span>
            <span class="photo-date">item.el.attr('data-date-photo')</span>`;
        }
      },
      callbacks: {
        open: () => {
          const magnificPopup = $.magnificPopup.instance,
            $arrows = $('.mfp-arrow');
          $('.mfp-arrow').remove();
          $arrows.insertAfter('.mfp-figure');
          $(document)
            .on('click', '.mfp-arrow-right', () => {
              magnificPopup.next();
            })
            .on('click', '.mfp-arrow-left', () => {
              magnificPopup.prev();
            });
          $('.popup-close').on('click', () => {
            $.magnificPopup.close();
          });
        },
        buildControls: () => {
          this.arrowLeft && this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
        },
        close: () => {
          document.getElementById('gallery-output').innerHTML = '';
        }
      }
    };

    [...this.imagePopupLink].map(item => {
      item.addEventListener('click', e => {
        e.preventDefault();
        const url = item.getAttribute('href'),
          xhr = new XMLHttpRequest();
        xhr.open('GET', url, false);
        xhr.send();
        if (xhr.status !== 200) {
          console.log('error');
        } else {
          const galleryContainer = document.getElementById('gallery-output');
          galleryContainer.innerHTML = xhr.responseText;
          const images = galleryContainer.getElementsByTagName('img');

          [...images].map(img => {
            this.imageArray.push({
              src: img.getAttribute('src'),
              title: img.getAttribute('alt'),
              datePhoto: img.getAttribute('data-date-photo')
            });
          });
        }
        if (xhr.readyState === 4) {
          $.magnificPopup.open(popupParams);
        }
      });
    });
  }

  _showAjaxPopup() {
    const popupParams = {
      type: 'ajax',
      classNameSelector: '.js-show-popup',
      mainClass: 'ajax-popup',
      callbacks: {
        ajaxContentAdded: () => {
          if (this.$gallery) {
            this._initGallery();
          }
          $('[data-tooltipster="inline"]').tooltipster({
            arrow: false,
            minWidth: 100,
            maxWidth: 380,
            side: ['bottom', 'right', 'left'],
            contentAsHTML: true,
            interactive: true
          });
        }
      }
    };

    const popup = new Popup(popupParams);
    popup.init();
  }

  _tdLocation() {
    this.tableBody.addEventListener('click', e => {
      const currentTd = e.target.closest('.table__td--location');
      if (currentTd !== null) {
        currentTd.classList.contains('table__td--location') &&
        (window.location = currentTd.parentNode.getAttribute('data-location'))
      }
    });
  }

  init() {
    this.tableBody && this._tdLocation();
    this.sectionEpigraphic && this._showAjaxPopup();
    this.sectionEpigraphic && this._viewEpigraphicPhotos();
  }

}
