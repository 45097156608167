import Player from '../../_partials/audio-player/audio-player';
import Pagination from '../../_partials/pagination/pagination';

export default class Interview {
  constructor() {
    this.name = 'interview';
    this.audioCollection = document.querySelectorAll('.audio-player');
    this.paginationContainer = document.querySelector('.page__content-pagination-audio');
  }

  init() {

    if (this.audioCollection) {
      [...this.audioCollection].map(audio => {
        this.player = new Player(audio);
        this.player.init(false);
      });
    }

    if (this.paginationContainer) {
      const options = {
        pageLimit: 10
      };
      this.pagination = new Pagination();
      this.pagination.init(options);
    }
  }
}

