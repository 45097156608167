import 'owl.carousel';
import Popup from '../common-functions/popup';
import ZoomImage from '../../_modules/zoom-image/zoom-image';

export default class GalleryThumbnails {
  constructor() {
    this.$gallery = document.querySelectorAll('.gallery2');
    this.innerImgClassName = '.gallery2--main';
    this.navElementClassName = '.owl-nav';
  }

  _initGallery(zoomImage) {
    let sync1 = $('.gallery2--main'); // main-slider
    let sync2 = $('.gallery2--thumbs'); // thumb-slider;
    let slidesPerPage = 4;
    let syncedSecondary = true;

    sync1
      .on('initialized.owl.carousel', () => {
        zoomImage && setTimeout(() => {
          this._addZoomImage();
        }, 10);
      })
      .owlCarousel({
        items: 1,
        slideSpeed: 2000,
        dots: false,
        loop: true,
        nav: true,
        responsiveRefreshRate: 200,
        onChanged: this._removeZoomImage
      })
      .on('changed.owl.carousel', syncPosition);

    sync2
      .on('initialized.owl.carousel', () => {
        sync2.find('.owl-item').eq(0).addClass('current');
      })
      .owlCarousel({
        items: slidesPerPage,
        margin: 10,
        dots: false,
        smartSpeed: 200,
        slideSpeed: 500,
        slideBy: slidesPerPage,
        responsiveRefreshRate: 100
      })
      .on('changed.owl.carousel', syncPosition2);

    sync2.on('click', '.owl-item', function (e) {
      e.preventDefault();
      let number = $(this).index();
      sync1.data('owl.carousel').to(number, 300, true);
    });

    function syncPosition(el) {
      let current = el.item.index;
      sync2
        .find('.owl-item')
        .removeClass('current')
        .eq(current)
        .addClass('current');
      let onscreen = sync2.find('.owl-item.active').length - 1;
      let start = sync2.find('.owl-item.active').first().index();
      let end = sync2.find('.owl-item.active').last().index();

      if (current > end) {
        sync2.data('owl.carousel').to(current, 100, true);
      }
      if (current < start) {
        sync2.data('owl.carousel').to(current - onscreen, 100, true);
      }
    }

    function syncPosition2(el) {
      if (syncedSecondary) {
        let number = el.item.index;
        sync1.data('owl.carousel').to(number, 100, true);
      }
    }
  }

  _addPopupPhoto() {
    const popupParams = {
      name: 'slider-thumbnails-photo',
      classNameSelector: '.gallery2__img',
      type: 'image',
      tLoading: 'Загрузка изображения #%curr%...',
      mainClass: 'popup-photo',
      gallery: {
        enabled: true,
        arrowMarkup: `<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%">
          <svg class="icon-arrow-left"><use xlink:href="#arrow-left"></use></svg>
          <svg class="icon-arrow-right"><use xlink:href="#arrow-right"></use></svg>
        </button>`,
        tCounter: ``
      },
      closeMarkup: `<button title="Закрыть модальное окно" type="button" class="mfp-close popup-close">
        <svg class="icon-close-popup"><use xlink:href="#close-big"></use></svg>
      </button>`,
      image: {
        tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
        titleSrc: item => {
          return `<span class="photo-caption">${item.el.attr('data-title')}</span>
          <span class="photo-date">${item.el.attr('data-date-photo')}</span>`;
        }
      },
      callbacks: {
        open: () => {
          const magnificPopup = $.magnificPopup.instance,
            $arrows = $('.mfp-arrow');
          $('.mfp-arrow').remove();
          $arrows.insertAfter('.mfp-figure');
          $(document)
            .on('click', '.mfp-arrow-right', () => {
              magnificPopup.next();
            })
            .on('click', '.mfp-arrow-left', () => {
              magnificPopup.prev();
            });
          $('.popup-close').on('click', () => {
            $.magnificPopup.close();
          });
        },
        buildControls: () => {
          this.arrowLeft && this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
        }
      }
    };

    const popup = new Popup(popupParams);

    popup.init();
  }


  _addZoomImage() {
    const zoomImageParams = {
      innerImgClassName: '.gallery2--main',
      imgClassName: '.gallery2__img',
      navElementClassName: '.owl-nav',
      imgItemClassName: '.owl-item'
    };
    let zoomImage = new ZoomImage(zoomImageParams);
    zoomImage.init();
  }

  init() {
    if (this.$gallery.length) {
      this._initGallery(true);
      this._addPopupPhoto();
    }
  }
}
