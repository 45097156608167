'use strict';

export default class ZoomImage {
  constructor(params) {
    this.name = 'zoom-image';
    console.log('%s module', this.name.toLowerCase());
    this.innerImgClassName = params.innerImgClassName;
    this.imgClassName = params.imgClassName;
    this.innerImgNode = document.querySelector(this.innerImgClassName);
    this.navElementClassName = params.navElementClassName;
    this.imgItemClassName = params.imgItemClassName;
    this.coordinates = null;
    this.imgPreviewSize = 0;
    this.zoomScale = 1.8;
    this.currentElem = null;
  }

  _str(el) {
    if(!el) return 'null';
    return el.className || el.tagName;
  }

  _getValue(val, min, max) {
    if (val < min) {
      return min;
    }
    if (val > max) {
      return max;
    }
    return val;
  }

  _getPreviewImgSize (img){
    return {
      width: img.offsetWidth,
      height: img.offsetHeight
    };
  }

  _getCursorCoordinate(evt) {
    if (evt.target.closest(this.innerImgClassName)) {
      let target = evt.target.closest(this.innerImgClassName);
      let targetCoords = target.getBoundingClientRect();

      let xCoord = evt.clientX - targetCoords.left;
      let yCoord = evt.clientY - targetCoords.top;
      return this.coordinates = {
        x: xCoord,
        y: yCoord
      };
    }
  };

  _updateCoordinate(evt) {
    let img = evt.target.parentElement.querySelector(this.imgClassName);
    let previewWidth = this.imgPreviewSize.width;
    let previewHeight = this.imgPreviewSize.height;
    let zoomWidthImg = previewWidth * this.zoomScale;
    let zoomHeigthImg = previewHeight * this.zoomScale;
    let xLimit = zoomWidthImg - previewWidth;
    let yLimit = zoomHeigthImg - previewHeight;
    let cursorCoordinates = this._getCursorCoordinate(evt);
    let axisX = this._getValue(cursorCoordinates.x, 0, xLimit) ;
    let axisY = this._getValue(cursorCoordinates.y, 0, yLimit);

    img.style.left = -axisX + 'px';
    img.style.top = -axisY + 'px';
    img.style.width = zoomWidthImg + 'px';
    img.style.height = zoomHeigthImg + 'px';
    img.style.position = 'absolute';
    img.style.maxWidth = 'none';
  }

  _addZoomImage(img) {
    this.imgPreviewSize = this._getPreviewImgSize(img);
  };

  _removeZoomImage(img) {
    this.imgPreviewSize = 0;
    img.style.left = '';
    img.style.top = '';
    img.style.width = '';
    img.style.height = '';
    img.style.position = '';
    img.style.maxWidth = '';
  };

  _onMouseOverImage(evt) {

    if(this.currentElem) return;
    let target = evt.target;

    while(target !== evt.currentTarget) {
      if(target.classList.contains('owl-item')){
        break;
      }
      target = target.parentNode;
    }

    if(target === evt.currentTarget) return;
    this.currentElem = target;
    this._addZoomImage(this.currentElem.querySelector(this.imgClassName));

  };

  _onMouseOutImage(evt) {

    if (!this.currentElem) return;
    let relatedTarget = evt.relatedTarget;
    if(relatedTarget) {
      while(relatedTarget) {
        if(relatedTarget === this.currentElem) return;
        relatedTarget = relatedTarget.parentNode;
      }
    }

    this._removeZoomImage(evt.target.parentElement.querySelector(this.imgClassName));
    this.currentElem = null;

  };

  _onMouseMoveCursor(evt) {
    if (!evt.target.closest(this.navElementClassName)) {
      this._updateCoordinate(evt);
    }
  }


  _addZoom() {
    this.innerImgNode.addEventListener('mouseover', evt => {
      this._onMouseOverImage(evt);
    });

    this.innerImgNode.addEventListener('mouseout', evt => {
      this._onMouseOutImage(evt);
    });

    this.innerImgNode.addEventListener('mousemove', evt => {
      this._onMouseMoveCursor(evt);
    });
  };

  init() {
    if (document.querySelectorAll(this.imgClassName)) {
      this._addZoom();
    }
  }
}
