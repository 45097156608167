import Tabs from '../../_partials/common-functions/tabs';

export default class ContentTabs {
  constructor() {
    this.name = 'content-tabs';
    this.sectionClassName = '.content-tabs';
    this.sectionNodeList = document.querySelectorAll(this.sectionClassName);
  }

  _initTabs() {
    const tabParams = {
      name: this.name,
      tabsNavInnerClassName: '.content-tabs',
      tabNavItemClassName: '.content-tabs__item',
      tabNavItemActiveClassName: '.content-tabs__item--active',
      tabNavItemActiveClassListName: 'content-tabs__item--active',
      tabNavLinkClassName: '.content-tabs__link',
      tabClassName: '.content-tabs__content-item',
      tabActiveClassListName: 'content-tabs__content-item--active'
    };
    const tabsContentCard = new Tabs(tabParams);
    tabsContentCard.init();
  }

  init() {
    if(this.sectionNodeList) {
      this._initTabs();
    }
  }
}